import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {TranslocoService} from '@jsverse/transloco';
import {Observable, of} from 'rxjs';
import {AlcedoTypeIcons} from '../../../../../alcedo/src/app/shared/properties/alcedo-type-icons.constant';
import {AlcedoUnits} from '../../../../../alcedo/src/app/shared/properties/alcedo-units.constant';
import {AlcedoUnit} from './alcedo-units.interface';
import {DataPoint} from './data-point.interface';

enum IoTType {
  ATMOSPHERIC_PRESSURE_ABSOLUTE = 'ATMOSPHERIC_PRESSURE_ABSOLUTE',
  ATMOSPHERIC_PRESSURE_AVERAGE = 'ATMOSPHERIC_PRESSURE_AVERAGE',
  CO2 = 'CO2',
  CO2_ALL_SENSE = 'CO2_ALL_SENSE',
  CO2_FILTERED = 'CO2_FILTERED',
  HUMIDITY = 'HUMIDITY',
  TEMPERATURE = 'TEMPERATURE',
  TEMPERATURE_ALT1 = 'TEMPERATURE_ALT1',
  TEMPERATURE_ALT2 = 'TEMPERATURE_ALT2',
  VOC = 'VOC'
}

@Injectable({providedIn: 'root'})
export class AlcedoUnitsService {
  private readonly iotTypeSortValuesMap: Record<IoTType, number>;

  constructor(
    private translate: TranslocoService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    this.registerIcon('comfort-comfortable', 'comfort-comfortable');
    this.registerIcon('comfort-still-comfortable', 'comfort-still-comfortable');
    this.registerIcon('comfort-uncomfortable', 'comfort-uncomfortable');
    this.registerIcon('quiz-character', 'quiz-character');

    this.iotTypeSortValuesMap = Object.values(IoTType).reduce((map, iotType, index) => {
      map[iotType] = index;
      return map;
    }, {}) as Record<IoTType, number>;
  }

  static getIcon(dataPoint: DataPoint): string {
    const unit = AlcedoUnitsService.getUnit(dataPoint);
    return (AlcedoTypeIcons[unit.alcedoTypeId] || '').replace('alcedo-icon-data-type-', '');
  }

  static getUnit(dataPoint: DataPoint): AlcedoUnit {
    // !!!WARNING using only alcedoTypeId, id and symbol -> no labels -> no unit label translation
    const unitId = dataPoint.unit;
    return AlcedoUnits[unitId];
  }

  // eslint-disable-next-line complexity
  getShortTypeName(dataPoint: DataPoint): Observable<string> {
    switch (dataPoint.iotType) {
      case IoTType.ATMOSPHERIC_PRESSURE_ABSOLUTE:
      case IoTType.ATMOSPHERIC_PRESSURE_AVERAGE:
        return this.translate.selectTranslate('PRESSURE');
      case IoTType.CO2:
      case IoTType.CO2_ALL_SENSE:
      case IoTType.CO2_FILTERED:
        return this.translate.selectTranslate('CO2');
      case IoTType.HUMIDITY:
        return this.translate.selectTranslate('HUMIDITY');
      case IoTType.TEMPERATURE:
      case IoTType.TEMPERATURE_ALT1:
      case IoTType.TEMPERATURE_ALT2:
        return this.translate.selectTranslate('TEMPERATURE');
      case IoTType.VOC:
        return this.translate.selectTranslate('VOC');
      default:
        return of(dataPoint.name);
    }
  }

  getShortTypeNameSortValue(dataPoint: DataPoint): number | string {
    return dataPoint.iotType ? this.iotTypeSortValuesMap[dataPoint.iotType] : dataPoint.name;
  }

  registerIcons(dataPoints: DataPoint[]) {
    dataPoints.forEach(dataPoint => {
      const icon = AlcedoUnitsService.getIcon(dataPoint);
      if (icon) {
        this.registerIcon(icon, 'data-type-' + icon);
      }
    });
  }

  private registerIcon(iconName: string, iconSVGName: string) {
    this.iconRegistry.addSvgIcon(iconName, this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/' + iconSVGName + '.svg'));
  }
}
