import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {DeviceType} from 'api/entities';
import {forkJoin, Observable, of, Subject, throwError as observableThrowError} from 'rxjs';
import {Subscription} from 'rxjs/internal/Subscription';
import {catchError, filter, map, switchMap, tap} from 'rxjs/operators';
import {UserSessionService} from '../../../../../alcedo/src/app/shared/entities/auth/user-session.service';
import {Alcedo7User} from '../../../../../alcedo/src/app/shared/entities/user/avelon-user.service';
import {Device, StoredDevice} from './device.interface';
import {DeviceService} from './device.service';
import {DevicesFilterService} from './devices-filter.service';
import {LiveValueService} from './live-value.service';

@Injectable({providedIn: 'root'})
export class IoTService {
  devices: Device[] = [];
  reloadDevices: Subject<void> = new Subject<void>();
  private liveValueSubjects: Map<number, Subject<any>> = new Map<number, Subject<any>>();
  private deviceIdToScroll: number;
  private resetDeviceIdToScroll$: Subscription;

  constructor(
    private deviceService: DeviceService,
    private liveValueService: LiveValueService,
    private filterService: DevicesFilterService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    localStorage.removeItem('selectedDevice'); // legacy storage key
  }

  static getStoredDevices(): StoredDevice[] {
    const storedDevicesData = localStorage.getItem('devices');
    return storedDevicesData ? JSON.parse(storedDevicesData) : [];
  }

  private static setStoreDevices(devices: StoredDevice[]): void {
    localStorage.setItem('devices', JSON.stringify(devices));
  }

  private static removeStoredDevice(device: Device): void {
    const storedDevices = IoTService.getStoredDevices();
    const i = storedDevices.findIndex(d => d.activationCode === device.activationCode);
    if (i > -1) {
      storedDevices.splice(i, 1);
      IoTService.setStoreDevices(storedDevices);
    }
  }

  private static addDeviceToStore(device: Device): void {
    const storedDevices = IoTService.getStoredDevices();
    const i = storedDevices.findIndex(d => d.activationCode === device.activationCode);
    if (i < 0) {
      storedDevices.push({
        id: device.id,
        name: device.name,
        activationCode: device.activationCode
      });
      IoTService.setStoreDevices(storedDevices);
    }
  }

  getDeviceByActivationCode(activationCode: string): Device {
    return this.devices.find(device => device.activationCode === activationCode);
  }

  getDeviceByID(id: number): Device {
    return this.devices.find(device => device.id === id);
  }

  /**
   * Register a new device to the device hub by its activation code.
   * @param activationCode The activation code of the new device.
   * @param goToDeviceHub If true, the app will navigate to the device hub after the device has been added successfully.
   * @param replaceUrl replace url in router state history
   */
  registerDevice(activationCode: string, goToDeviceHub: boolean, replaceUrl?: boolean): Observable<Device> {
    return this.deviceService.getDevice(activationCode, false).pipe(
      switchMap(device => {
        const existingDevice: Device = this.getDeviceByID(device.id) || {
          activationCode,
          dataPoints: [],
          deviceType: DeviceType.IOT_GENERIC,
          id: undefined,
          name: '',
          dtype: 'IoTDevice'
        };

        DeviceService.mergeDevice(existingDevice, device);
        this.addDevice(existingDevice);

        if (goToDeviceHub) {
          this.goToDeviceHub(existingDevice, replaceUrl);
          if (existingDevice && this.devices.length > 10 && this.devices.indexOf(existingDevice) > 10) {
            this.filterService.searchDeviceName = existingDevice.name;
          }
        }
        return of(existingDevice);
      }),
      catchError(err => {
        if (goToDeviceHub) {
          this.goToDeviceHub();
        }
        return observableThrowError(err);
      })
    );
  }

  /**
   * Navigate to device hub and scroll to the given device.
   * @param device Device to which the page should scroll.
   * @param replaceUrl param to replace url in router state history
   */
  goToDeviceHub(device?: Device, replaceUrl?: boolean) {
    this.setDeviceToScroll(device);
    this.router.navigate(['../../'], {
      relativeTo: this.activatedRoute,
      replaceUrl
    });
  }

  setDeviceToScroll(device: Device): void {
    this.deviceIdToScroll = this.devices.length > 1 && device ? device.id : null;

    if (!this.resetDeviceIdToScroll$ && this.deviceIdToScroll) {
      // Reset device to scroll to if a route other than Device Hub was reached, so we don't trigger an undesired scroll to device.
      this.resetDeviceIdToScroll$ = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => event.url !== '/' && (this.deviceIdToScroll = null));
    }
  }

  getDeviceIdToScroll(): number {
    const deviceIdToScroll = this.deviceIdToScroll;
    this.deviceIdToScroll = null;
    return deviceIdToScroll;
  }

  /**
   * Removes a device from the device hub.
   * This will also remove it from local storage and unsubscribe any remaining subscriptions.
   * @param device Device to be removed from the app.
   */
  removeDevice(device: Device) {
    if (this.liveValueSubjects.has(device.id)) {
      this.liveValueSubjects.get(device.id).unsubscribe();
    }
    LiveValueService.unregisterLiveValueUpdates(device);

    const existingDevice = this.getDeviceByID(device.id);
    if (existingDevice) {
      const deviceIndex = this.devices.indexOf(existingDevice);
      this.devices.splice(deviceIndex, 1);
      if (device.scanned) {
        IoTService.removeStoredDevice(device);
      }
    }
  }

  private addDevice(device: Device) {
    if (device) {
      const existingDevice = this.getDeviceByID(device.id);
      if (!existingDevice) {
        device.scanned = true;
        this.devices.push(device);
        this.sortDevices();
        IoTService.addDeviceToStore(device);
        this.liveValueSubjects.set(device.id, this.registerLiveValueUpdates(device));
      }
    }

    return device;
  }

  loadDevices(): Observable<Device[]> {
    const selectedClientId = UserSessionService.getSelectedClientId();
    const clientId = selectedClientId || (Alcedo7User.currentUser ? Alcedo7User.currentUser.clientId : null);
    this.devices = IoTService.getStoredDevices() as Device[];

    if (Alcedo7User.currentUser && clientId) {
      return this.loadAllDevices(clientId);
    } else {
      this.sortDevices();
      return this.loadDevicesFromLocalStorage();
    }
  }

  private loadDevicesFromLocalStorage(): Observable<Device[]> {
    const fetchDevices = [];
    this.devices.forEach(device => {
      // Fetch latest device information
      const device$ = this.deviceService.getDevice(device.activationCode, false).pipe(
        tap(data => {
          DeviceService.mergeDevice(device, data);
          device.scanned = true;
          this.liveValueSubjects.set(device.id, this.registerLiveValueUpdates(device));
        }),
        catchError(() => {
          device.scanned = true;
          return of(device);
        })
      );
      fetchDevices.push(device$);
    });
    if (!fetchDevices.length) {
      fetchDevices.push(of(1));
    }
    return forkJoin(fetchDevices);
  }

  private loadAllDevices(clientId: number): Observable<Device[]> {
    return this.deviceService.loadDevices(clientId).pipe(
      switchMap(privateDevices => {
        this.devices = this.devices.filter(device => {
          const existingDevice = privateDevices.find(privateDevice => privateDevice.activationCode === device.activationCode);
          return !existingDevice;
        });
        return this.loadDevicesFromLocalStorage().pipe(map(() => privateDevices));
      }),
      map(privateDevices => {
        privateDevices.forEach(device => this.liveValueSubjects.set(device.id, this.registerLiveValueUpdates(device)));
        this.devices = this.devices.concat(privateDevices);
        this.sortDevices();
        return this.devices;
      })
    );
  }

  /**
   * Sort list of devices alphabetically by device name.
   */
  private sortDevices() {
    this.devices.sort((a: Device, b: Device) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      }

      return 0;
    });
  }

  private registerLiveValueUpdates(device: Device): Subject<any> {
    return this.liveValueService.registerLiveValueUpdates(device);
  }
}
