export const LocalStorageKeys = {
  AddressingSystems: {
    Source: {
      dataPointFilterId: 'AddressingSystems.Source.DataPointFilterId',
      dataPointId: 'AddressingSystems.Source.DataPointId',
      fileGuid: 'AddressingSystems.Source.FileGuid'
    }
  },
  AlarmChain: {
    skipSMSFeeInformation: 'AlarmChain.SkipSMSFeeInformation'
  },
  Announcements: {
    dismissedIds: 'Announcements.dismissedIds'
  },
  CarbonFootprint: {
    adHocChart: 'CarbonFootprint.AdHocChart',
    hideAssignedDataPointsInAssignmentsDialog: 'CarbonFootprint.HideAssignedDataPointsInAssignmentsDialog'
  },
  Chart: {
    LegendHiddenPlotIds: 'Chart.LegendHiddenPlotIds'
  },
  CDP: {
    selectedTool: 'CalculatedDataPoints.SelectedTool'
  },
  DashboardFilterPanel: {
    closed: 'DashboardFilterPanel.Closed',
    selectedTab: 'DashboardFilterPanel.SelectedTab',
    tabSize: 'DashboardFilterPanel.Size',
    groupTagsFilter: 'DashboardFilterPanel.GroupTagsFilter'
  },
  DataPoints: {
    timeObject: 'DataPoints.timeObject'
  },
  DataPointsAutoTranslate: {
    source: 'DataPointsAutoTranslate.Source',
    targets: 'DataPointsAutoTranslate.Targets'
  },
  GenerateProjectStructure: {
    projectStructureCardSize: 'GenerateProjectStructure.ProjectStructureCardSize',
    groupsPreviewCardSize: 'GenerateProjectStructure.GroupsPreviewCardSize'
  },
  MeterNetwork: {
    adHocChart: 'MeterNetwork.AdHocChart',
    selectedTool: 'MeterNetwork.SelectedTool'
  },
  Schematic: {
    adHocChart: 'Schematic.AdHocChart',
    selectedTool: 'Schematic.SelectedTool',
    undoRedoActions: 'Schematic.UndoRedoActions'
  },
  ShapeEditor: {
    behaviorPanelSize: 'ShapeEditor.BehaviorPanelSize'
  },
  UserSession: {
    /** @deprecated */
    stayLoggedInKey: 'Alcedo7.stayLoggedIn',
    /** @deprecated */
    accessTokenKey: 'Alcedo7.Token',
    /** @deprecated */
    refreshTokenKey: 'Alcedo7.RefreshToken',

    userGuidKey: 'Alcedo7.UserGUID',
    iotAppSelectedClientIdKey: 'selectedClientId',
    sessionDummyKey: 'sessionDummy',
    urlRefKey: 'urlRef',
    languageChangeKey: 'languageChange'
  },
  Lumina: {
    baseSystemType: 'Lumina.baseSystemType',
    baseSystemUrl: 'Lumina.baseSystemUrl',
    ignoreImportErrors: 'Lumina.ignoreImportErrors',
    machineIsLumina: 'Lumina.machine',
    machineLuminaUrl: 'Lumina.machine.url'
  }
};
